import React from "react"
import "../delete_account.css"
import { Button } from "antd"
import BreadcrumbRevokeMember from "../breadcrumb/breadcrumb"

const Step1 = ({ setCurrentCpn }) => {
  return (
    <div className="container">
      <BreadcrumbRevokeMember paths={["TOP", "企業情報", "退会手続き申請"]} />
      <div className="bodyContent" style={{ height: "601px" }}>
        <div
          style={{
            paddingTop: "50px",
            width: "fit-content",
            marginLeft: "auto",
            marginRight: "auto",
          }}
        >
          <svg
            width="56"
            height="55"
            viewBox="0 0 56 55"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M25.4019 4.5C26.5566 2.5 29.4434 2.5 30.5981 4.5L49.2176 36.75C50.3723 38.75 48.9289 41.25 46.6195 41.25H9.38046C7.07106 41.25 5.62768 38.75 6.78238 36.75L25.4019 4.5Z"
              fill="#EA6D69"
            />
            <path
              d="M26.725 28.575H29.275L29.95 20.025L30.075 16.3H25.925L26.05 20.025L26.725 28.575ZM28 35.225C29.25 35.225 30.2 34.325 30.2 33.025C30.2 31.7 29.25 30.8 28 30.8C26.75 30.8 25.8 31.7 25.8 33.025C25.8 34.325 26.725 35.225 28 35.225Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="headText">退会手続きの前にご確認ください。</div>
        <div className="bodyText">
          <div>
            会員を退会された場合には、現在マイページに設定している情報や各種取引履歴が
            <span className="redtext">すべて削除</span>されます。
          </div>
          <div>
            そのため、取引を再開される場合には再度会員情報のご入力が必要になりますのでご了承ください。
          </div>
          <div style={{ marginTop: "36px" }}>
            また、
            <span className="redtext">
              取引中の商品がある場合には退会出来ません。
            </span>
          </div>
          <div>取引完了後に改めて退会手続きを行ってください。</div>
        </div>
        <div
          className="customPadding"
          style={{ textAlign: "center", marginTop: "94px" }}
        >
          <Button
            type="primary"
            className="funcbtn"
            style={{
              backgroundColor: "#E60020",
            }}
          >
            企業情報に戻る
          </Button>
        </div>
        <div className="customPadding" style={{ textAlign: "center" }}>
          <Button
            type="primary"
            className="funcbtn"
            style={{
              backgroundColor: "#ECB427",
              borderColor: "#ECB427",
              marginTop: "26px",
            }}
            onClick={() => {
              setCurrentCpn("Step2")
            }}
          >
            退会手続き画面へ
          </Button>
        </div>
        <div style={{ textAlign: "center", marginTop: "16px" }}>
          <span className="gotohome">TOPページへ</span>
        </div>
      </div>
    </div>
  )
}

export default Step1
